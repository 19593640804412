import { Layout } from "../../common/Layout"
import React from "react"
import { SEO } from "../../common/SEO"
import { Wrapper, Image, SectionR, SectionL, Title, Content } from "./TemplatePageCms.style"
import { useDetectDevice } from "./../../../lib/useDetectDevice"

interface Props {
  data: any
  className: string
}

function TemplatePageCmsView({ data, className }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  const { strapiGmPageCms } = data
  const text = strapiGmPageCms.Text?.data.Text
  const text2 = strapiGmPageCms.Text2?.data.Text2
  console.log("le text", text)

  return (
    <Layout>
      <SEO
        title={strapiGmPageCms.Seo?.SeoTitle}
        description={strapiGmPageCms.Seo?.SeoDescription}
      />
      <Wrapper className={classNames}>
        <Title>{strapiGmPageCms.Title}</Title>
        {strapiGmPageCms.Image && <Image data={strapiGmPageCms?.Image} />}
        {/* <ReactMarkdown>{strapiGmPageCms.Content?.data?.Content}</ReactMarkdown> */}
        <Content dangerouslySetInnerHTML={{ __html: text }} />
        {strapiGmPageCms.RubriqueDroite && (
          <SectionR className={classNames}>
            <div
              dangerouslySetInnerHTML={{
                __html: strapiGmPageCms.RubriqueDroite?.text.data.text,
              }}
            />
            <Image data={strapiGmPageCms.RubriqueDroite?.image} />
          </SectionR>
        )}
        {strapiGmPageCms.RubriqueGauche && (
          <SectionL className={classNames}>
            <Image data={strapiGmPageCms.RubriqueGauche?.image} />
            <div
              dangerouslySetInnerHTML={{
                __html: strapiGmPageCms.RubriqueGauche?.text.data.text,
              }}
            />
          </SectionL>
        )}
        <Content dangerouslySetInnerHTML={{ __html: text2 }} />
      </Wrapper>
    </Layout>
  )
}

export default TemplatePageCmsView
