import * as React from "react"
import { graphql } from "gatsby"
import TemplatePageCmsView from "../components/specific/TemplatePageCms/TemplatePageCms.view"

export const query = graphql`
  query cmsPageQuery($id: String) {
    strapiGmPageCms(id: { eq: $id }) {
      Content {
        data {
          Content
        }
      }
      childStrapiGmPageCmsContentTextnode {
        childMarkdownRemark {
          html
        }
     }
      Seo {
        SeoTitle
        SeoDescription
      }
      Text {
        data {
          Text
        }
      }
      Title
      Image {
        name
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      RubriqueDroite {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        text {
          data {
            text
          }
        }
      }
      RubriqueGauche {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        text {
          data {
            text
          }
        }
      }
      Text2 {
        data {
          Text2
        }
      }
    }
  }
`

const TemplatePageCms = ({ data, className }) => {
  return <TemplatePageCmsView data={data} className={className} />
}

export default TemplatePageCms
