import styled from "styled-components"

import { CommonTitle } from "./../../common/Texts"
import { ImageWithLinkOrNot } from "../../common/ImageWithLinkOrNot"

export const Wrapper = styled.div`
  margin: 50px auto;
  width: 90%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
`

export const SectionR = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 15px;
  width: 100%;
  picture img {
    height: 100%;
  }
  &.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
export const SectionL = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 15px;
  width: 100%;
  picture img {
    height: 100%;
  }
  &.mobile {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const Content = styled.div`
  width: 100%;
  figure {
    width: auto;
  }
  figure img {
    height: 100%;
  }
`

export const Title = styled(CommonTitle)`
  font-family: ${({ theme }) => theme.typography.h1};
`

export const Image = styled(ImageWithLinkOrNot)``
